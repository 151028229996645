"use strict";

console.log('app.js is ready');

(function swiper(){
	const slider = new Swiper('.js-slider', {
		// Optional parameters
		slidesPerView: 1,
		loop: false,
		autoHeight: false,
		spaceBetween: 0,
		speed: 800,
		simulateTouch: false,
		effect: "fade",

		autoplay: {
			delay: 3000,
			disableOnInteraction: true,
			pauseOnMouseEnter: true
		},

		// If we need pagination
		pagination: {
			el: '.js-slider-pagination',
			bulletClass: 'slider__pagination-bullet slider__pagination-bullet_type_round',
			bulletActiveClass: 'slider__pagination-bullet_state_active',
			type: 'custom',
			renderCustom: function (swiper, current, total) {
				let pgntHTML = '';
				for (let i = 1; i <= total; i++) {
					if (current == i) {
						pgntHTML += '<li class="' + swiper.params.pagination.bulletClass + ' ' + swiper.params.pagination.bulletActiveClass + '"></li>';
					} else {
						pgntHTML += '<li class="' + swiper.params.pagination.bulletClass + '"></li>';
					}
				}
				return pgntHTML;
			},
			clickable: true
		},

		// Navigation arrows
		navigation: {
			nextEl: '.js-slider-button-next',
			prevEl: '.js-slider-button-prev',
			disabledClass: 'slider__navigation-button_state_disabled'
		}
	});

	const news = new Swiper('.js-news', {
		// Optional parameters
		slidesPerView: 1,
		loop: false,
		// autoHeight: false,
		spaceBetween: 30,
		simulateTouch: false,

		// autoplay: {
		// 	delay: 3000,
		// 	disableOnInteraction: true,
		// 	pauseOnMouseEnter: true
		// },

		// If we need pagination
		pagination: false,

		// Navigation arrows
		navigation: {
			nextEl: '.js-news-button-next',
			prevEl: '.js-news-button-prev',
			disabledClass: 'carousel__navigation-button_state_disabled'
		},
		breakpoints: {
			480: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
})();

(function countUp(){
	const triggers = document.getElementsByClassName('js-count-up');

	// The animation function, which takes an Element
	const animateCountUp = function(el, options) {
		// How long you want the animation to take, in ms
		const animationDuration = options.duration;
		// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
		const frameDuration = 1000 / 60;
		// Use that to calculate how many frames we need to complete the animation
		const totalFrames = Math.round( animationDuration / frameDuration );
		// An ease-out function that slows the count as it progresses. See https://gist.github.com/gre/1650294
		const linear = t => t
		const easeInQuad = t => Math.pow(t, 2)
		const easeOutQuad = t => 1 - easeInQuad(1 - t)
		const easeInOutQuad = t => t < .5 ? easeInQuad(t * 2) / 2 : easeOutQuad(t * 2 - 1) / 2 + .5
		const easeInCubic = t => Math.pow(t, 3)
		const easeOutCubic = t => 1 - easeInCubic(1 - t)
		const easeInOutCubic = t => t < .5 ? easeInCubic(t * 2) / 2 : easeOutCubic(t * 2 - 1) / 2 + .5
		const easeInQuart = t => Math.pow(t, 4)
		const easeOutQuart = t => 1 - easeInQuart(1 - t)
		const easeInOutQuart = t => t < .5 ? easeInQuart(t * 2) / 2 : easeOutQuart(t * 2 - 1) / 2 + .5
		const easeInQuint = t => Math.pow(t, 5)
		const easeOutQuint = t => 1 - easeInQuint(1 - t)
		const easeInOutQuint = t => t < .5 ? easeInQuint(t * 2) / 2 : easeOutQuint(t * 2 - 1) / 2 + .5

		let ease;
		switch (options.ease) {
			case 'easeInQuad' :
				ease = easeInQuad;
				break;
			case 'easeOutQuad' :
				ease = easeOutQuad;
				break;
			case 'easeInOutQuad' :
				ease = easeInOutQuad;
				break;
			case 'easeInCubic' :
				ease = easeInCubic;
				break;
			case 'easeOutCubic' :
				ease = easeOutCubic;
				break;
			case 'easeInOutCubic' :
				ease = easeInOutCubic;
				break;
			case 'easeInQuart' :
				ease = easeInQuart;
				break;
			case 'easeOutQuart' :
				ease = easeOutQuart;
				break;
			case 'easeInOutQuart' :
				ease = easeInOutQuart;
				break;
			case 'easeInQuint' :
				ease = easeInQuint;
				break;
			case 'easeOutQuint' :
				ease = easeOutQuint;
				break;
			case 'easeInOutQuint' :
				ease = easeInOutQuint;
				break;
			case 'linear' :
			default:
				ease = linear;
				break;
		}

		let frame = 0;
		const countTo = parseInt( options.countTo, 10 );
		// Start the animation running 60 times per second
		const counter = setInterval( () => {
			// Calculate our progress as a value between 0 and 1
			// Pass that value to our easing function to get our
			// progress on a curve
			const progress = ease( frame / totalFrames );
			// Use the progress value to calculate the current count
			const currentCount = Math.round( countTo * progress );

			// If the current count has changed, update the element
			if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
				el.innerHTML = currentCount;
				el.classList.add('js-animation-in-progress');
			}

			// If we’ve reached our last frame, stop the animation
			if ( frame === totalFrames ) {
				clearInterval( counter );
				el.classList.remove('js-animation-in-progress');
				el.classList.add('js-animation-is-ended');
			}
			frame++;
		}, frameDuration );
	};

	// The check visability function, which takes an Element
	function isElementInViewport (el) {
		var rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
			rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
		);
	}

	Array.prototype.forEach.call(triggers, function(trigger){
		trigger.setAttribute('data-count-to', trigger.innerHTML);
		trigger.innerHTML = 0;
	});

	// Setup isScrolling variable
	let isScrolling;
	// Listen for scroll events
	window.addEventListener('scroll', function ( event ) {
		// Clear our timeout throughout the scroll
		window.clearTimeout( isScrolling );

		// Set a timeout to run after scrolling ends
		isScrolling = setTimeout(function() {

			// Run the callback
			// console.log( 'Scrolling has stopped.' );
			Array.prototype.forEach.call(triggers, function(trigger){
				if(isElementInViewport(trigger) && !(trigger.classList.contains('js-animation-in-progress') || trigger.classList.contains('js-animation-is-ended'))) {
					let options = {
						duration : trigger.getAttribute('data-duration') ? trigger.getAttribute('data-duration') : '800',
						ease : trigger.getAttribute('data-ease'),
						countTo : trigger.getAttribute('data-count-to'),
					}
					animateCountUp(trigger, options)
				}
			});

		}, 100);

	}, false);
})();

(function scrollTo(){
	const triggers = document.getElementsByClassName('js-scroll-to');

	Array.prototype.forEach.call(triggers, function(trigger){
		const target = trigger.getAttribute('href').split("#")[1];
		const hrefPathname = trigger.getAttribute('href').split("#")[0];
		const currentPathname = window.location.pathname;

		trigger.addEventListener('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			console.log('clicked on ' + trigger);
			if(hrefPathname === currentPathname || hrefPathname === '') {
				// console.log('hrefPathname equal to currentPathname');
				let elTarget = document.getElementById(target);
				// console.log('target is #' + target);
				if (elTarget !== null) {
					let mobNavOpened = document.getElementsByClassName('page')[0].classList.contains('page_state_nav-opened');
					let modalOpened =  document.getElementsByClassName('page')[0].classList.contains('page_state_modal-opened');

					if(mobNavOpened) {
						// console.log('Closing sidebar');
						document.getElementsByClassName('page')[0].classList.toggle('page_state_nav-opened');
					}
					if(modalOpened) {
						// console.log('Closing modal');
						document.getElementsByClassName('modal_state_open')[0].classList.toggle('modal_state_open');
						document.getElementsByClassName('page')[0].classList.toggle('page_state_modal-opened');
					}

					let headerHeight = document.getElementsByClassName('page__header')[0].offsetHeight
					let y = elTarget.getBoundingClientRect().top + window.pageYOffset;
					if(y > headerHeight) {
						y = y - headerHeight;
					}
					console.log('Target found. Scrolling');
					// setTimeout(function() {window.scroll({top: y, behavior: 'smooth'});},1)
					window.scroll({top: y, behavior: 'smooth'});
					// console.log('headerHeight is ' + headerHeight);
					// console.log('window.scroll({top: ' + y + ', behavior: \'smooth\'});');
					// console.log('console.log trigger scroll?!');
				} else {
					console.log('target not found');
				}
			} else {
				// console.log('hrefPathname not equal to currentPathname');
				// console.log('hrefPathname is ' + hrefPathname);
				// console.log('currentPathname is ' + currentPathname);
				window.location.href = trigger.getAttribute('href');
			}
		});
	});
})();

(function toggle() {
	const triggers = document.getElementsByClassName('js-toggle');

	Array.prototype.forEach.call(triggers, function(trigger){
		const toggleTargets = trigger.getAttribute('data-target').split(", ");
		const toggleClass = trigger.getAttribute('data-class').split(", ");
		// console.log(toggleTargets);
		// console.log(toggleClass);

		trigger.addEventListener('click', function(e){
			e.preventDefault();
			// console.log('clicked on ' + this.getAttribute('class'));
			let errors = [];
			// Collecting errors
			Array.prototype.forEach.call(toggleTargets, function(toggleTarget, index){
				if(typeof document.getElementsByClassName(toggleTarget)[0] === 'undefined') {
					errors.push(toggleTarget);
				}
			});
			if(errors.length > 0) {
				errors.forEach(function(error){
					console.log('target element .' + error + ' not found');
				});
			} else {
				// No erros! Do the job
				Array.prototype.forEach.call(toggleTargets, function(toggleTarget, index){
					document.getElementsByClassName(toggleTarget)[0].classList.toggle(toggleClass[index]);
				});
			}
		});
	});
})();

(function tabs() {
	const tabs = document.getElementsByClassName('js-tabs');

	Array.prototype.forEach.call(tabs, function(tabs){
		const navItems = tabs.getElementsByClassName('nav__item');

		Array.prototype.forEach.call(navItems, function(navItem){
			const navLink = navItem.children[0];
			const targetID = navLink.getAttribute('href').substring(1);
			const target = document.getElementById(targetID);

			navLink.addEventListener('click', function(evt){
				evt.preventDefault();
				if(!target.classList.contains('tabs__pane_state_current')) {
					navItem.classList.add('nav__item_state_current');
					target.classList.add('tabs__pane_state_current');
					const sibs = Array.prototype.filter.call(target.parentNode.children, function(child){
						return child !== target;
					});
					Array.prototype.forEach.call(sibs, function(sib){
						sib.classList.remove('tabs__pane_state_current');
					});
					const sibsNavItems = Array.prototype.filter.call(navItem.parentNode.children, function(child){
						return child !== navItem;
					});
					Array.prototype.forEach.call(sibsNavItems, function(sibsNavItem){
						sibsNavItem.classList.remove('nav__item_state_current');
					});
				}
			});
		});
	});
})();
